// Use this file to override default variables
:root {
  //Primary UoA Colours
  --uoa-dark-blue: #00467f;
  --uoa-light-blue: #009ac7;
  --uoa-silver: #8d9091;
  --uoa-blue-tint: #0062B2;

  //Secondary UoA Colours
  --uoa-dark-grey: #333333;
  --uoa-mid-grey: #404040;
  --uoa-body-grey: #4a4a4c;
  --uoa-silver-lining: #bec3c4;
  --uoa-input-grey: #d9d9d9;
  --uoa-light-grey: #f2f2f2;

  //Event card widths
  --card-column-width: 269px;
  --card-column-width-mobile: 304px;
  --card-column-gap: 18px;
}
