/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. 
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
.aria-element-hidden {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
}

.breadcrumbs > ion-breadcrumb {
  --color: var(--uoa-light-blue);
  --color-active: #0080a7;
  --color-hover: var(--uoa-dark-blue);
  font-weight: bold;
  max-width: 100%;
}

.breadcrumbs > ion-breadcrumb::part(separator) {
  color: var(--uoa-body-grey);
}

button.uoa-btn,
button.uoa-icon-btn {
  border-radius: 32px;
  border-width: 2px;
  border-style: solid;
  transition: 0.15s;
  color: white;
}

button.uoa-btn > div {
  margin: 7px 7px 10px 7px;
}

button.uoa-icon-btn > div {
  display: grid;
  align-items: center;
  grid-template-columns: auto 20%;
  margin: 7px 0px 7px 7px;
}

.uoa-btn-text {
  grid-row: 1;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  margin: auto;
  margin-bottom: 3px;
}

.uoa-btn-icon {
  grid-row: 1;
  pointer-events: none;
  margin-top: auto;
  margin-bottom: auto;
  width: 20px;
  height: 20px;
}

button.btn-dark-blue {
  border-color: var(--uoa-dark-blue);
  background-color: var(--uoa-dark-blue);
}

button.btn-dark-blue:hover {
  color: var(--uoa-dark-blue);
  background-color: white;
}

button.btn-dark-blue-inverse {
  border-color: var(--uoa-dark-blue);
  background-color: white;
  color: var(--uoa-dark-blue);
}

button.btn-dark-blue-inverse:hover {
  color: white;
  background-color: var(--uoa-dark-blue);
}

button.btn-disabled {
  border-color: var(--uoa-silver);
  background-color: var(--uoa-silver);
}

.sc-ion-select-popover-md {
  color: var(--uoa-dark-blue) !important;
  font-size: 18px !important;
  font-weight: bold !important;
  --background-focused: var(--uoa-light-blue) !important;
}

.custom-popover::part(native) {
  --background: var(--uoa-light-blue);
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
}

.default-button {
  --color: var(--uoa-light-blue);
  --background: #FFFFFF;
  --box-shadow: none;
}

.button-clicked {
  --color: #FFFFFF;
  --background: var(--uoa-light-blue);
  --box-shadow: none;
}

uoa-search-autocomplete {
  --checkbox-columns: 3;
}